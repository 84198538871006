<template>
  <base-section
    id="customer-reviews"
    space="64"
  >
    <base-section-heading
      icon="mdi-chat-processing"
      space="0"
      title="Referenzen"
    />

    <v-carousel
      class="mt-n12"
      height="300"
    >
      <v-carousel-item
        v-for="(customer, i) in customers"
        :key="i"
      >
        <v-container class="fill-height">
          <v-responsive
            class="mx-auto shrink text-center"
            max-width="90%"
            width="700"
          >
            <base-body
              :text="customer.review"
              tag="em"
              class="mb-6 d-block"
            />

            <base-title
              :title="customer.name"
              space="0"
            />

            <base-subtitle :title="customer.role" />
          </v-responsive>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCustomerReviews',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      customers: [
        {
          review: '"Ich freue mich, dir hier bald einige Eindrücke zu vermitteln."',
          name: 'MB',
          role: 'Matzingen',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #customer-reviews
    .v-carousel__controls
      background: transparent

    .base-body
      line-height: 2
</style>
